// 値から単位を抜く関数 16px - px ;
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }
  @return $number;
}


// pxからvwに変換
@function convert-small($num, $width: $viewport-small) {
  @return (1vw * $num / $width * 100);
}

@function convert-medium($num, $width: $viewport-medium) {
  @return (1vw * $num / $width * 100);
}

@function convert-large($num, $width: $viewport-large) {
  @return (1vw * $num / $width * 100);
}

// rr()をremに計算後、単位をremに変換
// Responsive Rem = rr
@function rr($number) {
  @return #{$number / 16 + rem};
}

// 割合 親要素のコンテンツ幅を元に％を割り出す式
// Percent = p 自分のコンテンツ幅 / 親のコンテンツ幅(paddingを除く内側)
@function p($thisWidth, $parentWidth) {
  @return ($thisWidth / $parentWidth) * 100%;
}