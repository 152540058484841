.p-error {
  margin: auto;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding: rr(32);
  color: #B3B3B3;
  font-family: futura-pt, "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
  font-weight: 400;
  line-height: 1.6;

  //.オープンサイト専用記述
  @at-root body > &:only-child {
    min-height: 100vh;
  }

  // .p-error__number
  &__number {
    line-height: 0.8;
    font-weight: 500;
    font-size: rr(80);
    color: currentColor;

    @include media-query-small {
      font-size: rr(110);
    }
  }

  // .p-error__contents
  &__contents {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  // .p-error__title
  &__title {
    margin-bottom: rr(10);
    font-size: rr(22);

    @include media-query-small {
      margin-bottom: rr(10);
      font-size: rr(29);
    }
  }

  // .p-error__description
  &__description {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: rr(32);
    padding: rr(12) 0;
    font-weight: bold;
    font-size: rr(14);
    color: $black__primary;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    border-image: linear-gradient(to right, $white__primary 0%, $black__primary 50%, $white__primary 100%);
    border-image-slice: 1;

    @include media-query-small {
      width: auto;
      margin-bottom: rr(48);
      padding: rr(18) rr(50);
      font-size: rr(18)
    }
  }

  // .p-error__link
  &__link {
    display: flex;
    justify-content: center;
    padding: rr(16) rr(12);
    width: 100%;
    min-width: rr(240);
    max-width: rr(250);
    font-weight: bold;
    font-size: rr(14);
    color: $white__primary;
    background-image: linear-gradient(45deg, #F093FB, #F5576C);

    @include media-query-small {
      width: auto;
      min-width: rr(240);
      max-width: none;
      padding: rr(15);
      font-size: rr(16)
    }

    &:visited {
      color: $white__primary;
    }
  }
}
