html {
  min-height: 0vw;
  font-size: calc(1rem + ((1vw - 3.74px) * 1.6086));
  @include media-query-extra-small {
    font-size: calc(1rem + ((1vw - 3.75px) * 0.8163));
  }
  @include media-query-small {
    font-size: calc(1rem + ((1vw - 16px) * 0.5489));
  }
  @include media-query-large {
    font-size: 16px;
  }
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}