//Responsive web design(プロパティ、SPの値、PCの値){};
@mixin rwd($propaty, $device_mobile_value, $device_deskto_value) {
  #{$propaty}: $device_mobile_value;
  @media screen and(min-width: #{$breakpoint}) {
    #{$propaty}: $device_deskto_value;
  }
}

// mediaquery
@mixin media-query-extra-small {
  @media print, screen and (min-width: $breakpoint-extra-small) {
    @content;
  }
}

@mixin media-query-small {
  @media print, screen and (min-width: $breakpoint-small) {
    @content;
  }
}

@mixin media-query-medium {
  @media print, screen and (min-width: $breakpoint-medium) {
    @content;
  }
}

@mixin media-query-large {
  @media print, screen and (min-width: $breakpoint-large) {
    @content;
  }
}

// position位置調整
@mixin position($position, $top, $left) {
	position: $position;
	top: $top;
	left: $left;
	transform: translateY(-$top) translateX(-$left);
	-webkit-transform: translateY(-$top) translateX(-$left);
}

@mixin clamp($line) {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $line;
  // paddingだと突き抜けるのでborder: transparent で対応
}

 // z-index
@mixin z-index($z-index-name) {
  z-index: map-get($z-index-layer, $z-index-name);
}

@mixin scroll-x {
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
      display: none;
  }
}


@mixin break-container {
  margin: 0 var(--break-container);
}


@mixin background__dot--black {
  background: {
    color: $black__primary;
    image:
    repeating-linear-gradient( 0deg,rgba($white__tertiary, .15) 0px 1px,	transparent 1px 2px),
    repeating-linear-gradient( 90deg,rgba($white__tertiary, .15) 0px 1px,	transparent 1px 2px);
  }
}

@mixin background__dot--white {
  background: {
    repeat: repeat;
    position: 0 0, 2px 2px;
    size: 4px 4px;
    color: rgba($white__primary , 0.9);
    image: linear-gradient(45deg, rgba($black__primary , 0.1) 25%, transparent 25%, transparent 75%, rgba($black__primary , 0.1) 75%, rgba($black__primary , 0.1)),
        linear-gradient(45deg, rgba($black__primary , 0.1) 25%, transparent 25%, transparent 75%, rgba($black__primary , 0.1) 75%, rgba($black__primary , 0.1));
  }
}

@mixin gradation-white {
  backface-visibility:hidden;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    $white__primary 60%,
    $white__primary 80%,
    $white__primary
  );
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  text-align: center;
  cursor: pointer;
  width: 100%;
}



@mixin aspect-ratio($width, $height, $max-width: none) {
  position: relative;
  height: fit-content;
  overflow: hidden;
  border-radius: var(--global-radius);
  @if ($max-width != 'none') {
    max-width: $max-width + px;
  }
  &::before {
    float: left;
    content: "";
    padding-top: round(($height / $width) * 100% * 100) / 100;
  }
  &::after {
    display: block;
    content: "";
    clear: both;
  }
  > img[class$="__image"] {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@mixin hover-scale-still() {
  cursor: pointer;
  transition: transform .3s ease, -webkit-transform .3s ease;
}

@mixin hover-scale-active() {
  cursor: pointer;
  transform: scale(1.08);
  transition: transform .3s ease, -webkit-transform .3s ease;
}

@mixin hover-color-still() {
  filter: grayscale(100%);
  transition: all .3s ease;
}

@mixin hover-color-active() {
  filter: grayscale(0%);
  transition: all .3s ease;
}

@mixin hover-text-still() {
  transition: color 0s ease;
}

@mixin hover-text-active() {
  color:$gray__quaternary;
}

@mixin hover-fill-still() {
  transition: fill 0s ease;
}

@mixin hover-fill-active($color) {
  fill: $color;
}

@mixin  hover-gradation-still() {
  opacity: 0;
  transition: opacity .3s ease;
  background-image: pink;
}

@mixin  hover-gradation-active() {
  opacity: 1;
}


@mixin font-size-s {
  font-size: var(--font-size-s);
}

@mixin font-size-m {
  font-size: var(--font-size-m);
  line-height: var(--line-height-m);
}

@mixin font-size-l {
  font-size: var(--font-size-m);
}
