// white
$white__primary: #fff;
$white__secondary: #fafafa;
$white__tertiary: #f2f2f2;
$white__quaternary: #ececec;

//gray
$gray__primary: #454545;
$gray__secondary: #909090;
$gray__tertiary: #d0d0d0;
$gray__quaternary: #b7b7b7;
$gray__quinary: #ccc;

// black
$black__primary: #222;
$black__secondary: #333;
$black__tertiary: #444;
$black__quaternary: #555;

// red
$red__primary: #b93b40;
$red__secondary: #fbf5f4;

//blue
$blue__primary:#fcfeff;